import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ServiceTracker from "./components/ServiceTracker/ServiceTracker";

const AppRouter = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ServiceTracker />
    }
    // {
    //   path: "/cl",
    //   element: <ServiceTracker lng="es_cl" />
    // },
    // {
    //   path: "/pe",
    //   element: <ServiceTracker lng="es_pe" />
    // }
    // {
    //   path: "/:market/:brand",
    //   element: <ServiceTracker lng={getLangCode(urlMarketCode)} />
    // }
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
