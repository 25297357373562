import style from "./serviceTrackerContent.module.scss";
import StatusMapDesktop from "./StatusMapDesktop/StatusMapDesktop";
import StatusMapMobile from "./StatusMapMobile/StatusMapMobile";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useState } from "react";
import { WrenchIcon } from "../../utils/svgIcons";
import { useTranslation } from "react-i18next";
import { checkWrenchIcon } from "../../utils/helpers";
import { ST_MARKET_CODE_KEY } from "../../utils/constants";

const ServiceTrackerContent = ({ data, handleBack }) => {
  const { t } = useTranslation();
  const market = localStorage.getItem(ST_MARKET_CODE_KEY);

  const tooltipColor = getComputedStyle(
    document.querySelector("body")
  ).getPropertyValue("--tooltip-color");

  const iconActiveColor = getComputedStyle(
    document.querySelector("body")
  ).getPropertyValue("--icon-active-color");

  const iconDisableColor = getComputedStyle(
    document.querySelector("body")
  ).getPropertyValue("--icon-disabled-color");

  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  return (
    <div className={style.right}>
      <div className={style.header}>
        {!checkWrenchIcon(market) && <WrenchIcon />}
        <h4>Service Tracker</h4>
      </div>
      <div className={style.info}>
        <div className={style.vehicleCont}>
          <div>
            <label>{t("license plate")}</label>
            <p className={style.licensePlate}>{data?.plate}</p>
          </div>
          <div>
            <label>{t("client")}</label>
            <p>{data?.customerName}</p>
          </div>
          <div>
            <label>{t("brand vehicle")}</label>
            <p className={style.vehicle}>{data?.vehicleName}</p>
          </div>
        </div>
        <div className={style.processCont}>
          <div>
            <label>{t("process")}</label>
            <p className={style.process}>{data?.statusDescription}</p>
          </div>
          <div>
            <label>{t("type of service")}</label>
            <p>{data?.serviceType}</p>
          </div>
          <div>
            <label>{t("consultant")}</label>
            <p>{data?.ownerName}</p>
          </div>
          {data?.email && (
            <div>
              <label>{t("email")}</label>
              <a href={`mailto:${data?.email}`}>
                <p>{data?.email}</p>
              </a>
            </div>
          )}
        </div>
      </div>
      {currentWidth >= 576 ? (
        <StatusMapDesktop
          data={data}
          tooltip={tooltipColor}
          iconActive={iconActiveColor}
          iconDisabled={iconDisableColor}
        />
      ) : (
        <StatusMapMobile
          data={data}
          tooltip={tooltipColor}
          iconActive={iconActiveColor}
          iconDisabled={iconDisableColor}
        />
      )}
      <button className={style.btn} onClick={handleBack}>
        {t("back")}
      </button>
    </div>
  );
};

export default ServiceTrackerContent;
