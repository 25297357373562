/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import style from "./serviceTracker.module.scss";
import ServiceTrackerFront from "../ServiceTrackerFront/ServiceTrackerFront";
import ServiceTrackerContent from "../ServiceTrackerContent/ServiceTrackerContent";
import img from "../../assets/images/800x 2.svg";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { mockData } from "./sampleData";
import { format } from "date-fns";
import fetchClient from "../../api/fetch";
import {
  checkEmail,
  checkMarketBrand,
  getBrandContentImg,
  getBrandImg,
  replaceCharacters
} from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { ST_BRAND_ID_KEY, ST_MARKET_CODE_KEY } from "../../utils/constants";
import { marketBrandsConfig } from "../../utils/marketBrands";

const ServiceTracker = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const plate = new URLSearchParams(window.location.search).get("licensePlate");
  const condition = new URLSearchParams(window.location.search).get(
    "licensePlate"
  );
  const brand = Number(localStorage.getItem(ST_BRAND_ID_KEY));
  const market = localStorage.getItem(ST_MARKET_CODE_KEY);

  const [data, setData] = useState({});
  const [mode, setMode] = useState("standby");
  const [view, setView] = useState("front");
  const [value, setValue] = useState(condition ? plate : "");
  const [error, setError] = useState("");

  useEffect(() => {
    if (condition) {
      handleSearch();
    }
  }, [condition]);

  const validateInput = (value) => {
    const specialCharRegex = /[^a-zA-Z0-9]/;
    if (specialCharRegex.test(value)) {
      setError(t("error special characters"));
      return false;
    }
    if (value.length > 8) {
      setError(t("error maximum input"));
      return false;
    }
    setError("");
    return true;
  };

  const handleChange = (e) => {
    // setShowError(false);
    setError("");
    const { value } = e.target;

    if (validateInput(value)) {
      setValue(value);
    }
  };

  // Prevent pasting special characters
  const handlePaste = (event) => {
    const paste = (event.clipboardData || window.clipboardData).getData("text");
    if (!validateInput(paste)) {
      event.preventDefault();
    }
  };

  const handleClear = () => {
    setValue("");
    setError("");
  };

  const handleBack = () => {
    setView("front");
    location.search = "";
  };

  const handleSearch = () => {
    //set vehicleStatus into description and int statusValue
    const matchProgress = (status) => {
      let statusDescription = "";
      let statusValue;
      //TODO: changed when vehicleStatus is clear
      switch (status) {
        case "E":
          statusDescription = t("entrance tech service");
          statusValue = 1;
          break;
        case "W":
          statusDescription = t("in service");
          statusValue = 2;
          break;
        case "T":
          statusDescription = t("vehicle ready");
          statusValue = 3;
          break;
        case "D":
          statusDescription = t("delivered");
          statusValue = 4;
          break;
        default:
          statusDescription = "";
          statusValue = "";
          break;
      }
      return { statusDescription, statusValue };
    };

    setMode("searching");
    const cond = value ? value : plate;
    const brandCode = marketBrandsConfig
      .find((item) => item.marketCode === market)
      .brands.find((item) => item.brandId === brand).brandCode;

    //check if plate is entered or coming from email
    // called with the plate entered towards the back end
    fetchClient()
      .get(`/tracker?licensePlate=${cond}&brandcode=${brandCode}`)
      .then((response) => {
        //check if plate is available if not show error
        if (response.status === 204) {
          // UNCOMMENT IF WANT TO USE MOCK DATA

          // const data = mockData;
          // const { statusDescription, statusValue } = matchProgress(
          //   data.vehicleStatus
          // ); //declare vehicleStatus into description and int value
          // const vehicleName = `${data.brandName} ${data.carModel}`;
          // setData({
          //   ...data,
          //   plate: replaceCharacters(data.plate),
          //   customerName: replaceCharacters(data.customerName),
          //   statusDescription: statusDescription,
          //   status: statusValue,
          //   vehicleName: vehicleName,
          //   dateIn: data.dateIn
          //     ? format(new Date(data.dateIn), "dd/MM/yyyy")
          //     : null,
          //   // hourIn: data.hourIn ? data.hourIn.slice(0, 5) : null,
          //   dateOut: data.dateOut
          //     ? format(new Date(data.dateOut), "dd/MM/yyyy")
          //     : null
          //   // hourOut: data.hourOut ? data.hourOut.slice(0, 5) : null
          // });
          // setView("content");
          setView("front");
          // setShowError(true);
          setError(t("license plate not found"));
          setMode("standBy");
        } else {
          const data = response.data;
          setMode("standBy");
          setView("content");
          setError("");
          const { statusDescription, statusValue } = matchProgress(
            data.vehicleStatus
          ); //declare vehicleStatus into description and int value
          const vehicleName = `${data.brandName} ${data.carModel}`;
          setData({
            ...data,
            plate: replaceCharacters(data.plate),
            customerName: replaceCharacters(data.customerName),
            statusDescription: statusDescription,
            status: statusValue,
            vehicleName: vehicleName,
            dateIn: data.dateIn
              ? format(new Date(data.dateIn), "dd/MM/yyyy")
              : null,
            // hourIn: data.hourIn ? data.hourIn.slice(0, 5) : null,
            dateOut: data.dateOut
              ? format(new Date(data.dateOut), "dd/MM/yyyy")
              : null,
            // hourOut: data.hourOut ? data.hourOut.slice(0, 5) : null
            email: checkEmail(data.serviceAdvisorEmail)
              ? data.serviceAdvisorEmail
              : null
          });
        }
      })
      .catch((error) => {
        // receive error service and stay page
        console.error("Error fetching data:", error);
        setView("content");
        setMode("standBy");
        setError(t("license plate not found"));
        location.search = "";
      });
  };

  return (
    <div className={`${style.container} ${style[view]}`}>
      <div className={style[view + "ImgCont"]}>
        <img
          src={
            checkMarketBrand(market, brand)
              ? view === "front"
                ? getBrandImg(brand)
                : getBrandContentImg(brand)
              : img
          }
          alt="cover img"
        />
      </div>
      {view === "front" ? (
        <ServiceTrackerFront
          value={value}
          handleChange={handleChange}
          handleClear={handleClear}
          handleSearch={handleSearch}
          handlePaste={handlePaste}
          error={error}
        />
      ) : (
        <ServiceTrackerContent data={data} handleBack={handleBack} />
      )}
      {mode === "searching" && (
        <div className={style.loader}>
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};

export default ServiceTracker;
