import bmwImg from "../assets/images/bmw-2-cover.jpg";
import miniImg from "../assets/images/mini-2-cover.jpg";
import motorradImg from "../assets/images/motorrad-2-cover.jpg";
import mbenzImg from "../assets/images/mercedez-cover.png";
import bmwContentImg from "../assets/images/bmw-content.png";
import miniContentImg from "../assets/images/mini-content.png";
import motorradContentImg from "../assets/images/motorrad-content.png";

export const brandSettingsConfig = [
  {
    brand: "BMW",
    brandId: 4,
    brandCoverImg: bmwImg,
    brandContentImg: bmwContentImg,
    styles: {
      primaryColor: "#0653b6",
      primaryDarkColor: "#262626",
      secondaryColor: "#1c69d4",
      textPrimary: "#262626",
      textSecondary: "#ffffff",
      iconActiveColor: "#1c69d4",
      iconDisableColor: "#bbbbbb",
      inputLabelFont: "BMW Bold",
      inputPlaceholderFont: "BMW Regular",
      inputLabelColor: "#262626",
      inputLabelTextTransform: "uppercase",
      inputPlaceholderColor: "#262626",
      inputPlaceholderTextTransform: "uppercase",
      inputBorderColor: "#262626",
      inputBorderRadius: "0px",
      wrenchIconColor: "#0653b6",
      loaderColor: "#1c69d4",
      btnPrimary: "#1c69d4",
      btnPrimaryFontColor: "#ffffff",
      btnPrimaryHover: "#0653b6",
      btnPrimaryHoverFontColor: "#ffffff",
      btnPrimaryDisabled: "#1c69d4",
      btnPrimaryDisabledBorderColor: "#1c69d4",
      btnPrimaryBorderColor: "#1c69d4",
      btnPrimaryBorderRadius: "0px",
      btnSecondary: "#4d4d4d",
      btnSecondaryFontColor: "#ffffff",
      btnSecondaryHover: "#262626",
      btnSecondaryHoverFontColor: "#ffffff",
      btnSecondaryHoverBorderColor: "#262626",
      btnSecondaryDisabled: "#4d4d4d42",
      btnSecondaryBorderColor: "#4d4d4d",
      btnSecondaryBorderRadius: "0px",
      btnBack: "#4d4d4d",
      btnBackFontColor: "#ffffff",
      btnBackHover: "#262626",
      btnBackDisabled: "#4d4d4d42",
      btnBackBorderColor: "#4d4d4d",
      btnBackBorderRadius: "0px",
      contentBgLeft: "#F2F2F2",
      contentBgRight: "#1C69D4",
      tooltipColor: "#1c69d4",
      tooltipBgColor: "#000000bf",
      tooltipFontColor: "#ffffff",
      fontRegular: "BMW Regular",
      fontBold: "BMW Bold",
      fontLight: "BMW Light"
    }
  },
  {
    brand: "Mini",
    brandId: 5,
    brandCoverImg: miniImg,
    brandContentImg: miniContentImg,
    styles: {
      primaryColor: "#006583",
      primaryDarkColor: "#000000",
      secondaryColor: "#ffffff",
      textPrimary: "#262626",
      textSecondary: "#ffffff",
      iconActiveColor: "#006583",
      iconDisableColor: "#B5B4B4",
      inputLabelFont: "Mini Serif Regular",
      inputPlaceholderFont: "Mini Serif Regular",
      inputLabelColor: "#404040",
      inputLabelTextTransform: "none",
      inputPlaceholderColor: "#878787",
      inputPlaceholderTextTransform: "none",
      inputBorderColor: "#D4D4D4",
      inputBorderRadius: "10px",
      wrenchIconColor: "#000000",
      loaderColor: "#1c69d4",
      btnPrimary: "#006583",
      btnPrimaryFontColor: "#ffffff",
      btnPrimaryHover: "#1F9CC2",
      btnPrimaryHoverFontColor: "#ffffff",
      btnPrimaryDisabled: "#B5B4B4",
      btnPrimaryDisabledBorderColor: "#B5B4B4",
      btnPrimaryBorderColor: "#006583",
      btnPrimaryBorderRadius: "30px",
      btnSecondary: "#000000",
      btnSecondaryFontColor: "#ffffff",
      btnSecondaryHover: "#525252",
      btnSecondaryHoverFontColor: "#ffffff",
      btnSecondaryHoverBorderColor: "#525252",
      btnSecondaryDisabled: "#4d4d4d42",
      btnSecondaryBorderColor: "#000000",
      btnSecondaryBorderRadius: "30px",
      btnBack: "#006583",
      btnBackFontColor: "#ffffff",
      btnBackHover: "#1F9CC2",
      btnBackDisabled: "#B5B4B4",
      btnBackBorderColor: "#1F9CC2",
      btnBackBorderRadius: "30px",
      contentBgLeft: "#F2F2F2",
      contentBgRight: "#006583",
      tooltipColor: "#006583",
      tooltipBgColor: "#000000bf",
      tooltipFontColor: "#ffffff",
      fontRegular: "Mini Sans Serif Regular",
      fontBold: "Mini Serif Bold",
      fontLight: "Mini Sans Serif Regular"
    }
  },
  {
    brand: "BMW Motorrad",
    brandId: 6,
    brandCoverImg: motorradImg,
    brandContentImg: motorradContentImg,
    styles: {
      primaryColor: "#0653b6",
      primaryDarkColor: "#262626",
      secondaryColor: "#1c69d4",
      textPrimary: "#262626",
      textSecondary: "#ffffff",
      iconActiveColor: "#0062FF",
      iconDisableColor: "#CCCCCC",
      inputLabelFont: "Motorrad Regular",
      inputPlaceholderFont: "Motorrad Regular",
      inputLabelColor: "#262626",
      inputLabelTextTransform: "none",
      inputPlaceholderColor: "#878787",
      inputPlaceholderTextTransform: "none",
      inputBorderColor: "#D4D4D4",
      inputBorderRadius: "10px",
      wrenchIconColor: "#0062FF",
      loaderColor: "#1c69d4",
      btnPrimary: "#ffffff",
      btnPrimaryFontColor: "#000000",
      btnPrimaryHover: "#0062FF",
      btnPrimaryHoverFontColor: "#ffffff",
      btnPrimaryDisabled: "#ffffff",
      btnPrimaryDisabledBorderColor: "#000000",
      btnPrimaryBorderColor: "#000000",
      btnPrimaryBorderRadius: "0px",
      btnSecondary: "#000000",
      btnSecondaryFontColor: "#ffffff",
      btnSecondaryHover: "#0062FF",
      btnSecondaryHoverFontColor: "#ffffff",
      btnSecondaryHoverBorderColor: "#0062FF",
      btnSecondaryDisabled: "#4d4d4d42",
      btnSecondaryBorderColor: "#000000",
      btnSecondaryBorderRadius: "0px",
      btnBack: "#000000",
      btnBackFontColor: "#ffffff",
      btnBackHover: "#0062FF",
      btnBackDisabled: "#A6A6A6",
      btnBackBorderColor: "#4d4d4d",
      btnBackBorderRadius: "0px",
      contentBgLeft: "#F2F2F2",
      contentBgRight: "#0062FF",
      tooltipColor: "#0062FF",
      tooltipBgColor: "#000000bf",
      tooltipFontColor: "#ffffff",
      fontRegular: "Motorrad Regular",
      fontBold: "Motorrad Bold",
      fontLight: "Motorrad Regular"
    }
  },
  {
    brandGroup: "Mercedes Benz",
    brandId: 20,
    brandContentImg: mbenzImg,
    styles: {
      primaryColor: "#0078D6",
      primaryDarkColor: "#000000",
      secondaryColor: "#ffffff",
      textPrimary: "#262626",
      textSecondary: "#ffffff",
      iconActiveColor: "#0078D6",
      iconDisableColor: "#bbbbbb",
      inputLabelFont: "Mercedez Regular",
      inputPlaceholderFont: "Mercedez Regular",
      inputLabelColor: "#000000",
      inputLabelTextTransform: "none",
      inputPlaceholderColor: "#878787",
      inputPlaceholderTextTransform: "none",
      inputBorderColor: "#d4d4d4",
      inputBorderRadius: "10px",
      wrenchIconColor: "#000000",
      loaderColor: "#0078D6",
      btnPrimary: "#0078D6",
      btnPrimaryFontColor: "#ffffff",
      btnPrimaryHover: "#014880",
      btnPrimaryHoverFontColor: "#ffffff",
      btnPrimaryDisabled: "#B5B4B4",
      btnPrimaryDisabledBorderColor: "#B5B4B4",
      btnPrimaryBorderColor: "#0078D6",
      btnPrimaryBorderRadius: "30px",
      btnSecondary: "#ffffff",
      btnSecondaryFontColor: "#000000",
      btnSecondaryHover: "#EBEBEB",
      btnSecondaryHoverFontColor: "#000000",
      btnSecondaryHoverBorderColor: "#000000",
      btnSecondaryDisabled: "#4d4d4d42",
      btnSecondaryBorderColor: "#000000",
      btnSecondaryBorderRadius: "30px",
      btnBack: "#0078D6",
      btnBackFontColor: "#ffffff",
      btnBackHover: "#014880",
      btnBackDisabled: "#1c69d44d",
      btnBackBorderColor: "#0078D6",
      btnBackBorderRadius: "30px",
      contentBgLeft: "#F2F2F2",
      contentBgRight: "#333333",
      tooltipColor: "#0078D6",
      tooltipBgColor: "#000000bf",
      tooltipFontColor: "#ffffff",
      fontRegular: "Mercedez Regular",
      fontBold: "Mercedez Bold",
      fontLight: "Mercedez Regular"
    }
  }
];
