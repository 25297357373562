import style from "./serviceTrackerFront.module.scss";
// import wrench from "../../assets/icons/wrench icon.svg";
// import * as lngs from "../../utils/translations";
import { WrenchIcon } from "../../utils/svgIcons";
import { useTranslation } from "react-i18next";
import { ST_BRAND_ID_KEY, ST_MARKET_CODE_KEY } from "../../utils/constants";
import {
  checkWrenchIcon,
  getPlateExample,
  getVehicleType
} from "../../utils/helpers";

const ServiceTrackerFront = ({
  handleSearch,
  error,
  value,
  handleChange,
  handleClear,
  handlePaste
}) => {
  const { t } = useTranslation();
  const market = localStorage.getItem(ST_MARKET_CODE_KEY);
  const brand = Number(localStorage.getItem(ST_BRAND_ID_KEY));

  return (
    <div className={style.right}>
      <div className={style.top}>
        <div>
          {!checkWrenchIcon(market) && <WrenchIcon />}
          <h3>Service Tracker</h3>
        </div>
        <p className={style.subtext}>
          {t("please enter", { type: t(getVehicleType(market, brand)) })}
        </p>
        <div className={style.input}>
          <label>{t("license plate")}</label>
          <input
            placeholder={t("license plate")}
            value={value}
            onChange={handleChange}
            onPaste={handlePaste}
            type="text"
          />
          <p className={error ? style.error : ""}>
            {!error
              ? `${t("example", {
                  plateSample: getPlateExample(market, brand)
                })}`
              : error}
          </p>
        </div>
      </div>
      <div className={style.btnGroup}>
        <button className={style.btn} onClick={handleClear}>
          {t("clear")}
        </button>
        <button className={style.btn} onClick={handleSearch} disabled={!value}>
          {t("search")}
        </button>
      </div>
    </div>
  );
};

export default ServiceTrackerFront;
