const cssChangeTheme = (themeConfig) => {
  const {
    primaryColor,
    primaryDarkColor,
    secondaryColor,
    textPrimary,
    textSecondary,
    iconActiveColor,
    iconDisableColor,
    inputLabelFont,
    inputPlaceholderFont,
    inputLabelColor,
    inputLabelTextTransform,
    inputPlaceholderColor,
    inputPlaceholderTextTransform,
    inputBorderColor,
    inputBorderRadius,
    wrenchIconColor,
    loaderColor,
    btnPrimary,
    btnPrimaryFontColor,
    btnPrimaryHover,
    btnPrimaryHoverFontColor,
    btnPrimaryDisabled,
    btnPrimaryDisabledBorderColor,
    btnPrimaryBorderColor,
    btnPrimaryBorderRadius,
    btnSecondary,
    btnSecondaryFontColor,
    btnSecondaryHover,
    btnSecondaryHoverFontColor,
    btnSecondaryHoverBorderColor,
    btnSecondaryDisabled,
    btnSecondaryBorderColor,
    btnSecondaryBorderRadius,
    btnBack,
    btnBackFontColor,
    btnBackHover,
    btnBackDisabled,
    btnBackBorderColor,
    btnBackBorderRadius,
    contentBgLeft,
    contentBgRight,
    tooltipColor,
    tooltipBgColor,
    tooltipFontColor,
    fontRegular,
    fontBold,
    fontLight
  } = themeConfig[0];
  const body = document.querySelector("body");

  const cssVars = [
    {
      variable: "--primary-color",
      value: primaryColor
    },
    {
      variable: "--primary-dark-color",
      value: primaryDarkColor
    },
    {
      variable: "--secondary-color",
      value: secondaryColor
    },
    {
      variable: "--text-primary",
      value: textPrimary
    },
    {
      variable: "--text-secondary",
      value: textSecondary
    },
    {
      variable: "--icon-active-color",
      value: iconActiveColor
    },
    {
      variable: "--icon-disabled-color",
      value: iconDisableColor
    },
    {
      variable: "--input-label-font",
      value: inputLabelFont
    },
    {
      variable: "--input-placeholder-font",
      value: inputPlaceholderFont
    },
    {
      variable: "--input-label-color",
      value: inputLabelColor
    },
    {
      variable: "--input-label-text-transform",
      value: inputLabelTextTransform
    },
    {
      variable: "--input-placeholder-color",
      value: inputPlaceholderColor
    },
    {
      variable: "--input-placeholder-text-transform",
      value: inputPlaceholderTextTransform
    },
    {
      variable: "--input-border-color",
      value: inputBorderColor
    },
    {
      variable: "--input-border-radius",
      value: inputBorderRadius
    },
    {
      variable: "--wrench-icon-color",
      value: wrenchIconColor
    },
    {
      variable: "--loader-color",
      value: loaderColor
    },
    {
      variable: "--btn-primary",
      value: btnPrimary
    },
    {
      variable: "--btn-primary-font-color",
      value: btnPrimaryFontColor
    },
    {
      variable: "--btn-primary-hover",
      value: btnPrimaryHover
    },
    {
      variable: "--btn-primary-hover-font-color",
      value: btnPrimaryHoverFontColor
    },
    {
      variable: "--btn-primary-disabled",
      value: btnPrimaryDisabled
    },
    {
      variable: "--btn-primary-disabled-border-color",
      value: btnPrimaryDisabledBorderColor
    },
    {
      variable: "--btn-primary-border-color",
      value: btnPrimaryBorderColor
    },
    {
      variable: "--btn-primary-border-radius",
      value: btnPrimaryBorderRadius
    },
    {
      variable: "--btn-secondary",
      value: btnSecondary
    },
    {
      variable: "--btn-secondary-font-color",
      value: btnSecondaryFontColor
    },
    {
      variable: "--btn-secondary-hover",
      value: btnSecondaryHover
    },
    {
      variable: "--btn-secondary-hover-font-color",
      value: btnSecondaryHoverFontColor
    },
    {
      variable: "--btn-secondary-hover-border-color",
      value: btnSecondaryHoverBorderColor
    },
    {
      variable: "--btn-secondary-disabled",
      value: btnSecondaryDisabled
    },
    {
      variable: "--btn-secondary-border-color",
      value: btnSecondaryBorderColor
    },
    {
      variable: "--btn-secondary-border-radius",
      value: btnSecondaryBorderRadius
    },
    {
      variable: "--btn-back",
      value: btnBack
    },
    {
      variable: "--btn-back-font-color",
      value: btnBackFontColor
    },
    {
      variable: "--btn-back-hover",
      value: btnBackHover
    },
    {
      variable: "--btn-back-disabled",
      value: btnBackDisabled
    },
    {
      variable: "--btn-back-border-color",
      value: btnBackBorderColor
    },
    {
      variable: "--btn-back-border-radius",
      value: btnBackBorderRadius
    },
    {
      variable: "--content-bg-left",
      value: contentBgLeft
    },
    {
      variable: "--content-bg-right",
      value: contentBgRight
    },
    {
      variable: "--tooltip-color",
      value: tooltipColor
    },
    {
      variable: "--tooltip-bg-color",
      value: tooltipBgColor
    },
    {
      variable: "--tooltip-font-color",
      value: tooltipFontColor
    },
    {
      variable: "--font-regular",
      value: fontRegular
    },
    {
      variable: "--font-bold",
      value: fontBold
    },
    {
      variable: "--font-light",
      value: fontLight
    }
  ];

  cssVars.forEach((item) => body.style.setProperty(item.variable, item.value));
};

export default cssChangeTheme;
