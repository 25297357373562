import React from "react";
import ReactDOM from "react-dom/client";
// import AppRouter from "./AppRouter";
import App from "./App";
import "./index.scss";

//Translation features
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import {
  ST_BRAND_ID_KEY,
  ST_LANGUAGE_KEY,
  ST_MARKET_CODE_KEY
} from "./utils/constants";

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    supportedLngs: ["en", "es_cl", "es_pe"],
    // debug: true,
    fallbackLng: "es",
    interpolation: {
      escapeValue: false
    },
    lng: localStorage.getItem(ST_LANGUAGE_KEY) || "en"
  });

//Initialized first localStorage
const market = new URLSearchParams(window.location.search)
  .get("market")
  .toLowerCase();
const brand = new URLSearchParams(window.location.search).get("brand");
localStorage.setItem(ST_MARKET_CODE_KEY, market);
localStorage.setItem(ST_BRAND_ID_KEY, brand);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
