import { useEffect } from "react";
import AppRouter from "./AppRouter";
import cssChangeTheme from "./utils/cssChangeTheme";
import { checkMarketBrand, getBrandStyles, getLangCode } from "./utils/helpers";
import {
  DEFAULT_LANGUAGE,
  ST_BRAND_ID_KEY,
  ST_LANGUAGE_KEY,
  ST_MARKET_CODE_KEY,
  SUPPORTED_LANGUAGES
} from "./utils/constants";
import { useTranslation } from "react-i18next";

const updateLocalStorageWithBrand = () => {
  const brandId = new URLSearchParams(window.location.search).get("brand");
  const marketId = new URLSearchParams(window.location.search)
    .get("market")
    .toLowerCase();

  if (brandId) {
    localStorage.setItem(ST_BRAND_ID_KEY, brandId);
  }

  if (marketId) {
    const lang = getLangCode(marketId);
    localStorage.setItem(ST_MARKET_CODE_KEY, marketId);
    localStorage.setItem(ST_LANGUAGE_KEY, lang);
  }
};

function App() {
  const { i18n } = useTranslation();
  const market = localStorage.getItem(ST_MARKET_CODE_KEY);
  const brand = Number(localStorage.getItem(ST_BRAND_ID_KEY));
  const hasAvailSettings = checkMarketBrand(market, brand);

  //Add functionality for multiple window tabs
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Tab has become visible, update local storage with the current brand
        updateLocalStorageWithBrand();
      }
    };
    // Initial local storage update on mount
    updateLocalStorageWithBrand();
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  //Set theme base on market and brand
  useEffect(() => {
    if (hasAvailSettings) {
      cssChangeTheme(getBrandStyles(Number(brand)));
    }

    let lang = getLangCode(market);
    if (lang) {
      lang.toLowerCase();
      const isSupportedLang = SUPPORTED_LANGUAGES.includes(lang);
      i18n.changeLanguage(isSupportedLang ? lang : DEFAULT_LANGUAGE);
      localStorage.setItem(ST_LANGUAGE_KEY, lang);
    }
  }, [hasAvailSettings, market, brand, i18n]);

  useEffect(() => {
    const app = document.querySelector(".App");
    if (app) {
      const postOutputHeight = () =>
        window.parent.postMessage(app.scrollHeight, "*");

      const heightObserver = new ResizeObserver(postOutputHeight);
      heightObserver.observe(app);

      return () => heightObserver.unobserve(app);
    }
  }, []);

  return <div className="App">{hasAvailSettings && <AppRouter />}</div>;
}

export default App;
