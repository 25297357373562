import { Tooltip, OverlayTrigger } from "react-bootstrap";
import style from "./statusMapDesktop.module.scss";
import {
  CalendarIcon,
  HorizontalLineAltIcon,
  HorizontalLineIcon,
  InfoIcon,
  KeyIcon,
  ServiceIcon,
  StoreIcon,
  VehicleIcon
} from "../../../utils/svgIcons";
import { ST_BRAND_ID_KEY, ST_MARKET_CODE_KEY } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import {
  getBrand,
  getCustomTooltipWord,
  getVehicleType
} from "../../../utils/helpers";

const StatusMapDesktop = ({ data, tooltip, iconActive, iconDisabled }) => {
  const { t } = useTranslation();
  const brand = Number(localStorage.getItem(ST_BRAND_ID_KEY));
  const market = localStorage.getItem(ST_MARKET_CODE_KEY);

  return (
    <div className={style.trackerDesktop}>
      <div className={style.icons}>
        <span>
          {data?.status >= 1 ? (
            <StoreIcon fill={iconActive} />
          ) : (
            <StoreIcon fill={iconDisabled} />
          )}
        </span>
        <span>
          {data?.status >= 2 ? (
            <ServiceIcon fill={iconActive} />
          ) : (
            <ServiceIcon fill={iconDisabled} />
          )}
        </span>
        <span>
          {data?.status >= 3 ? (
            <VehicleIcon fill={iconActive} />
          ) : (
            <VehicleIcon fill={iconDisabled} />
          )}
        </span>
        <span>
          {data?.status >= 4 ? (
            <KeyIcon fill={iconActive} />
          ) : (
            <KeyIcon fill={iconDisabled} />
          )}
        </span>
      </div>
      <div className={style.lines}>
        <span className={style.initial}>
          <div className={data?.status >= 1 ? style.active : ""}></div>
        </span>
        <span
          className={`${style.ets} ${data?.status >= 2 ? style.active : ""}`}
        >
          <HorizontalLineIcon
            fill={data?.status >= 2 ? iconActive : iconDisabled}
          />
        </span>
        <span></span>
        <span></span>
        <span></span>
        <span
          className={`${style.rtd} ${data?.status >= 4 ? style.active : ""}`}
        >
          <HorizontalLineIcon
            fill={data?.status >= 4 ? iconActive : iconDisabled}
          />
        </span>
        <span></span>
        <span></span>
        <span></span>
        <span
          className={`${style.str} ${data?.status >= 3 ? style.active : ""}`}
        >
          <HorizontalLineAltIcon
            fill={data?.status >= 3 ? iconActive : iconDisabled}
          />
        </span>
        <span></span>
      </div>
      <div className={style.text}>
        <div>
          <div className={style.label}>
            <p className={data?.status >= 1 ? style.active : ""}>
              {t("entrance tech service")}
            </p>
            <span className={style.tooltip}>
              <OverlayTrigger
                overlay={(props) => (
                  <Tooltip {...props}>
                    <span>
                      {t("tooltip entrance", {
                        brand: getBrand(market, brand),
                        type: t(getVehicleType(market, brand)),
                        tooltipWord: t(getCustomTooltipWord(market, brand))
                      })}
                    </span>
                  </Tooltip>
                )}
                placement="bottom"
              >
                <span>
                  <InfoIcon fill={tooltip} />
                </span>
              </OverlayTrigger>
            </span>
          </div>
          {data?.status >= 1 && (
            <div className={style.dateTime}>
              <div className={style.date}>
                <CalendarIcon fill="#262626" />
                <p>{data?.dateIn}</p>
              </div>
              {/* <div className={style.time}>
                <img src={clock} alt="clock icon" />
                <p>{data?.hourIn}</p>
              </div> */}
            </div>
          )}
        </div>
        <div>
          <div className={style.label}>
            <p className={data?.status >= 2 ? style.active : ""}>
              {t("in service")}
            </p>
            <span className={style.tooltip}>
              <OverlayTrigger
                overlay={(props) => (
                  <Tooltip {...props}>
                    {t("tooltip service", { brand: getBrand(market, brand) })}
                  </Tooltip>
                )}
                placement="bottom"
              >
                <span>
                  <InfoIcon fill={tooltip} />
                </span>
              </OverlayTrigger>
            </span>
          </div>
          {/* {data?.status >= 2 && (
            <div className={style.dateTime}>
              <div className={style.date}>
                <img src={calendar} alt="calendar icon" />
                <p>26/08/2023</p>
              </div>
              <div className={style.time}>
                <img src={clock} alt="clock icon" />
                <p>8:00</p>
              </div>
            </div>
          )} */}
        </div>
        <div>
          <div className={style.cont}>
            <div className={style.label}>
              <p className={data?.status >= 3 ? style.active : ""}>
                {t("vehicle ready")}
              </p>
              <span className={style.tooltip}>
                <OverlayTrigger
                  overlay={(props) => (
                    <Tooltip {...props}>
                      {t("tooltip ready", {
                        brand: getBrand(market, brand),
                        type: t(getVehicleType(market, brand))
                      })}
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <span>
                    <InfoIcon fill={tooltip} />
                  </span>
                </OverlayTrigger>
              </span>
            </div>
            {/* {data?.progress >= 3 && (
              <div className={style.dateTime}>
                <div className={style.date}>
                  <img src={calendar} alt="calendar icon" />
                  <p>26/08/2023</p>
                </div>
                <div className={style.time}>
                  <img src={clock} alt="clock icon" />
                  <p>8:00</p>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div>
          <div className={style.cont}>
            <div className={style.label}>
              <p className={data?.status >= 4 ? style.active : ""}>
                {t("delivered")}
              </p>
              <span className={style.tooltip}>
                <OverlayTrigger
                  overlay={(props) => (
                    <Tooltip {...props}>
                      {t("tooltip delivered", {
                        brand: getBrand(market, brand)
                      })}
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <span>
                    <InfoIcon fill={tooltip} />
                  </span>
                </OverlayTrigger>
              </span>
            </div>
            {data?.status >= 4 && (
              <div className={style.dateTime}>
                <div className={style.date}>
                  <CalendarIcon fill="#262626" />
                  <p>{data?.dateOut}</p>
                </div>
                {/* <div className={style.time}>
                  <img src={clock} alt="clock icon" />
                  <p>{data?.hourOut}</p>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusMapDesktop;
