import axios from "axios";
import { marketBrandsConfig } from "./marketBrands";
import { brandSettingsConfig } from "./globalCssVariables";

export const searchData = (market, licensePlate) => {
  return axios.get(`/check-vehicle?license_plate=${licensePlate}`);
};

export const replaceCharacters = (input) => {
  if (!input) return ""; // Return empty string if input is empty or undefined
  let result = "";
  let isFirstWord = true;
  let isFirstChar = true;
  let spaceDetected = false;
  // Iterate through each character in the input string
  for (let i = 0; i < input.length; i++) {
    const currentChar = input.charAt(i);
    if (currentChar === " " || currentChar === "/" || currentChar === ".") {
      // If the current character is a space, slash, or dot, mark the next character as the start of a new word
      isFirstWord = false;
      isFirstChar = true;
      spaceDetected = true;
      result += currentChar;
    } else {
      // If it's not a space, slash, or dot
      if (isFirstWord) {
        // If it's the first word, retain all characters
        result += currentChar;
      } else {
        // If it's not the first word
        if (isFirstChar) {
          // If it's the first character of the second word, retain it
          result += currentChar;
          isFirstChar = false;
        } else {
          // If it's not the first character of the second word, replace it with "*"
          result += "*";
        }
      }
    }
  }

  // If no space, slash, or dot is detected, replace the 5th character with "*"
  if (!spaceDetected && input.length > 5) {
    const prefix = input.slice(0, 4);
    const asterisks = "*".repeat(input.length - prefix.length);
    result = prefix + asterisks;
  }

  return result;
};

export const checkEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const getBrandImg = (brandId) => {
  return brandSettingsConfig
    .filter((item) => item.brandId === brandId)
    .map((item) => item.brandCoverImg);
};

export const getBrandContentImg = (brandId) => {
  return brandSettingsConfig
    .filter((item) => item.brandId === brandId)
    .map((item) => item.brandContentImg);
};

export const checkMarketBrand = (marketId, brandId) => {
  if (
    marketBrandsConfig
      .find((item) => item.marketCode === marketId)
      ?.brands.find((item) => item.brandId === Number(brandId))?.brandId
  ) {
    return true;
  }
};

export const getBrandStyles = (brandId) => {
  if (brandId) {
    return brandSettingsConfig
      .filter((item) => item.brandId === brandId)
      .map((item) => item.styles);
  } else {
    return null;
  }
};

export const getLangCode = (marketId) => {
  return marketBrandsConfig.find((item) => item.marketCode === marketId)
    ?.languageCode;
};

export const getBrand = (marketId, brandId) => {
  return marketBrandsConfig
    .find((item) => item.marketCode === marketId)
    ?.brands.find((item) => item.brandId === brandId).brand;
};

export const getMarket = (marketId) => {
  return marketBrandsConfig.find((item) => item.marketCode === marketId).market;
};

export const checkWrenchIcon = (marketId) => {
  return marketBrandsConfig
    .find((item) => item.marketCode === marketId)
    .brands.find((item) => item.brand)
    .brand.includes("BMW", "Mini", "Motorrad");
};

export const getVehicleType = (marketId, brandId) => {
  return marketBrandsConfig
    .find((item) => item.marketCode === marketId)
    .brands.find((item) => item.brandId === brandId).vehicleType;
};

export const getCustomTooltipWord = (marketId, brandId) => {
  return marketBrandsConfig
    .find((item) => item.marketCode === marketId)
    .brands.find((item) => item.brandId === brandId).tooltipWord;
};

export const getPlateExample = (marketId, brandId) => {
  return marketBrandsConfig
    .find((item) => item.marketCode === marketId)
    .brands.find((item) => item.brandId === brandId).plateExample;
};
