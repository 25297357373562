export const marketBrandsConfig = [
  {
    market: "Chile",
    marketCode: "cl",
    brands: [
      {
        brand: "BMW",
        brandId: 4,
        brandCode: "B",
        vehicleType: "vehicle",
        tooltipWord: "recepcionado",
        plateExample: "XXXXYY"
      },
      {
        brand: "MINI",
        brandId: 5,
        brandCode: "M",
        vehicleType: "vehicle",
        tooltipWord: "recepcionado",
        plateExample: "XXXXYY"
      },
      {
        brand: "BMW Motorrad",
        brandId: 6,
        brandCode: "C",
        vehicleType: "motorcycle",
        tooltipWord: "recepcionada",
        plateExample: "XXXYY"
      }
    ],
    languageCode: "es_cl"
  },
  {
    market: "Peru",
    marketCode: "pe",
    brands: [
      {
        brand: "BMW",
        brandId: 4,
        brandCode: "B",
        vehicleType: "vehicle",
        tooltipWord: "recepcionado",
        plateExample: "XXXYYY"
      },
      {
        brand: "MINI",
        brandId: 5,
        brandCode: "M",
        vehicleType: "vehicle",
        tooltipWord: "recepcionado",
        plateExample: "XXXYYY"
      },
      {
        brand: "BMW Motorrad",
        brandId: 6,
        brandCode: "C",
        vehicleType: "motorcycle",
        tooltipWord: "recepcionada",
        plateExample: "XXXYYY"
      }
    ],
    languageCode: "es_pe"
  },
  {
    market: "Philippines",
    marketCode: "ph",
    brands: [
      {
        brand: "Mercedes Benz",
        brandId: 20,
        brandCode: "Z",
        vehicleType: "vehicle",
        tooltipWord: "received",
        plateExample: "XXXYYY"
      }
    ],
    languageCode: "en"
  }
];
