import axios from "axios";
import Cookie from "./Cookies";
import { ApigeeConfig, environment } from "../environments/environments";

const fetchClient = () => {
  const client = axios.create({
    baseURL: environment.StatusURL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookie.get("st_token")}`
    }
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error.response.status) {
        case 401:
          return refreshTokenAndRetryRequest(error);
        default:
          return Promise.reject(error);
      }
    }
  );
  return client;
};

//handle expired token and refresh automatically
const refreshTokenAndRetryRequest = (error) => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams();
    params.append("client_id", ApigeeConfig.client_id);
    params.append("client_secret", ApigeeConfig.client_secret);
    params.append("grant_type", "client_credentials");
    axios
      .post(environment.TokenURL, params) // called apigee token
      .then((response) => {
        Cookie.set("st_token", response.data.access_token);
        // Retry the request with the new token
        const config = error.config;
        config.headers.Authorization = `Bearer ${response.data.access_token}`;
        axios.request(config).then(resolve).catch(reject);
      })
      .catch((error) => {
        console.error("Error refreshing token:", error);
        reject(error);
      });
  });
};

export default fetchClient;
