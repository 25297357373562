// This file is for the addresses of the service tracker services and their accesses to Apigee
let Url;
let user;
let pass;

// Check the application domain
if (window.location.hostname.startsWith('dev.')) {
    // Environment variables for the development environment (dev)
    Url = process.env.REACT_APP_API_URL_DEV;
    user = process.env.REACT_APP_CLIENT_ID_DEV;
    pass = process.env.REACT_APP_CLIENT_SECRET_DEV;
} else if (window.location.hostname.startsWith('qa.')) {
    // Environment variables for the quality environment (qa)
    Url = process.env.REACT_APP_API_URL_QA;
    user = process.env.REACT_APP_CLIENT_ID_QA;
    pass = process.env.REACT_APP_CLIENT_SECRET_QA;
} else if (window.location.hostname.startsWith('servicetracker.')) {
    // Environment variables for other environments (production, etc.)
    Url = process.env.REACT_APP_API_URL_PROD;
    user = process.env.REACT_APP_CLIENT_ID_PROD;
    pass = process.env.REACT_APP_CLIENT_SECRET_PROD;
}
else {
    // Environment variables for local environments
    Url = process.env.REACT_APP_API_URL_LOCAL;
    user = process.env.REACT_APP_CLIENT_ID_LOCAL;
    pass = process.env.REACT_APP_CLIENT_SECRET_LOCAL;
}

// Define the URLs for the service endpoints and concatenate with environment variables
export const environment = {
    TokenURL: `${Url}/oauth/token`, // Endpoint to obtain access token
    StatusURL: `${Url}/service-tracker` // Endpoint for the service tracker
};

// Apigee configuration with environment variables
export const ApigeeConfig = {
    client_id: user, // Client ID obtained from environment variables
    client_secret: pass, // Client secret obtained from environment variables
    grant_type: "client_credentials" // Grant type
};
